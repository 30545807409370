import * as s from '../../styles/produto.module.scss';
import Button from '../../../components/button';
import CTA from '../../../components/cta';
import Features from '../../../components/features';
import Layout from '../../../components/layout';
import Product from '../../../components/product';
import React, { ReactElement } from 'react';

export default function MR1(): ReactElement {
  return (
    <Layout title="MRE">
      <main>
        <Product
          title="MRE"
          description="O Sistema de Esquadrias da SysRS."
          alt="Imagem MRE"
          imgName="mre"
          className={`${s.Section} u-nav-clear-padding u-background-gray`}
          reverse
        >
          <Button to="/produtos/mre/novidades" secondary outline>Novidades</Button>
        </Product>

        <section className={s.About}>
          <div className={s.About_box}>
            <h1 className="title">Sobre o MRE</h1>
            <h2 className="subtitle">Veja os seus recursos e tecnologias</h2>
            <p>
              O MR1 é um <b>poderoso ERP</b> modulável que se adapta a seu segmento mercantil. Baseado na arquitetura
              cliente/servidor, foi projetado trabalhar com banco de dados relacional, garantindo a <b>segurança e
                integridade</b> de seus dados. Devido a sua modularização, você poderá contratar apenas as partes do sistema
              que irá utilizar, gerando um <b>custo menor para sua empresa</b>.
            </p>
          </div>
        </section>

        <Features
          title="Recursos"
          subtitle="Veja algumas coisas que o MRE pode fazer"
          name="mre"
          list={[
            'Não sei quais recursos adicionar aqui',
            'Ver com o Celso o que vamos colocar',
            'Tem que ser uma lista legal que venda',
            'Uau como o MR1 é legal, muito show',
            'Mais tópicos',
            'Não sei quais recursos adicionar aqui',
            'Ver com o Celso o que vamos colocar',
            'Tem que ser uma lista legal que venda',
            'Uau como o MR1 é legal, muito show',
          ]}
          primary
        />

        <CTA />
      </main>
    </Layout>
  )
}
